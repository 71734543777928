<template>
    <v-layout row>
        <v-flex xs12 sm8 offset-sm2 lg4 offset-lg4>
            <v-card>
                <v-card-title>
                    Verify Email
                </v-card-title>
                <v-card-text>
                    You must verify your email address before logging in for the
                    first time. Please check your email and click the verify
                    link to enter the knowledgebase.
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: 'Login',
    data: () => ({}),
    methods: {
        login() {
            this.$auth.loginWithRedirect()
        },
    },
}
</script>
