<template>
    <v-layout row>
        <v-flex xs12 sm8 offset-sm2 lg4 offset-lg4>
            <v-card>
                <v-card-title>
                    Unauthorized
                </v-card-title>
                <v-card-text>
                    You are logged in as
                    {{ this.$store.getters.userForPortalAccess.email }} but that
                    account is not authorized to view this content. Please
                    contact the site administrator and ask them to add your
                    account to the list of authorized accounts.
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: 'Login',
    data: () => ({}),
    methods: {
        login() {
            this.$auth.loginWithRedirect()
        },
    },
}
</script>
